<template>
  <div @click="goVideo" ref="wrapper" class="video-container">
    <div v-if="tag" class="media-tag"></div>
    <VideoImage v-if="video && height" class="image-wrapper" isMobile :width="width" :style="{height: height + 'px'}" :src="video.coverImgUrl" />
    <div v-if="video" class="info-wrapper">
      <p class="title">{{video.name}}</p>
      <p class="detail">
        <span><span class="tag" :key="c.id" v-for="c in (video.typeArr || video.categories || []).slice(0,2)">{{typeof c === 'string' ? c : c.name}}</span></span>
        <!-- <span class="issue" v-if="video.issue"></span> -->
      </p>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import VideoImage from "@c/VideoImage.vue";
import { ref } from '@vue/reactivity';
import { onMounted, onUnmounted } from '@vue/runtime-core';
// import { getOrder } from "@api";
export default {
  name: "newVideoItem",
  components: {
    VideoImage
  },
  props: {
    video: Object,
    width: Number,
    isMobile: Boolean,
    tag: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const router = useRouter()
    const height = ref(0)
    const wrapper = ref(null)
    const timer = ref(0)
    const refreshHeight = () => height.value =  wrapper.value.getBoundingClientRect().width * 0.5625
    const refreshHeightNext = (delay = 0) => setTimeout(refreshHeight, delay)
    const debounceRefreshHeight = () => {
      clearTimeout(timer.value)
      timer.value = refreshHeightNext(100)
    }
    onMounted(refreshHeight)
    window.addEventListener("orientationchange", refreshHeightNext, false)
    window.addEventListener("resize", debounceRefreshHeight, false)
    onUnmounted(() => {
      window.removeEventListener("orientationchange", refreshHeightNext)
      window.removeEventListener("resize", debounceRefreshHeight)
    })
    return {
      height,
      wrapper,
      goVideo () {
        if (props.video.mediaType === 0) {
          router.push('/video/' + props.video.id)
        } else {
          router.push('/audio/' + props.video.id)
        }
      }
    }
  }
};
</script>


<style scoped>
.video-container {
  position: relative;
}
.cover-wrapper {
  height: 0;
  padding-bottom: 66%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
}
.bottom-wrapper {
  text-align: right;
  color: #666;
}
.image-wrapper {
  width: 100%;
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}

.info-wrapper {
  margin-top: 8px;
}

.title,.detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #272727;
  font-family: '微软雅黑';
  height: 18px;
  font-weight: bold;
  line-height: 18px;
}
.detail {
  margin-top: 5px;
  color: #82756D;
  font-family: '微软雅黑';
  font-size: 11px;
  display: flex;
  justify-content: space-between;
}
.issue {
  color: #272727;
}
.tag {
  margin-right: 10px;
}
.media-tag {
  border: 34px solid transparent;
  border-bottom-color: #ca4140;
  width: 0px;
  position: absolute;
  right: 0;
  transform: translateY(-50%) translateX(50%) rotate(45deg);
}
.media-tag:after {
  content: '音频';
  position: absolute;
  white-space: nowrap;
  transform: translateX(-50%) translateY(11px);
  font-size: 14px;
  color: #fff;
}
@media screen and (max-width: 770px) {
  .title,.detail {
    font-size: 10px;
  }
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 16px;
  }
  .detail {
    font-size: 14px;
  }
}
</style>
